export const BRAND_COLORS = {
  PRIMARY: "#3F50AA",
  SECONDARY: "#FF7A21",
  LIGHT_PRIMARY: "#69A2F7",
  LIGHT_SECONDARY: "#FF9E41",
  OFF_WHITE: "#EFF2F9",
  WHITE: "#FFFFFF",
  LIGHT_BLUE: "#C9DDF2",
  DARK_BLUE: "#1070CA",
  RED: "#EC4C47",
};

export const COLORS = {
  BLACK: "#637280",
};

export const PIE_CHART_COLORS = {
  MUTED_BORDER: "#EDF0F2",
  DARK_NEUTRAL: "#234361",
  BASE_YELLOW: "#F7D154",
  BASE_BLUE: "#1070CA",
  BASE_RED: "#EC4C47",
  BASE_GREEN: "#47B881",
};

export const SIDEBAR_COLORS = {
  DARK_GRAY: "#526070",
  GRAY: "#83909f",
  LIGHT_BLUE: "#f3f8fe",
};

export const ISSUES_COLORS = {
  HIGH: "red",
  NORMAL: "yellow",
  MULTIPLE_ISSUES: "neutral",
};

export const GRAPH_DATASET_COLORS = [
  "GoldenRod",
  "DarkSeaGreen",
  "Tomato",
  "Thistle",
  "CornflowerBlue",
  "SandyBrown",
  "CadetBlue",
  "PaleGoldenRod",
  "PaleVioletRed",
  "LightSkyBlue",
  "MediumPurple",
  "MediumAquaMarine",
  "DodgerBlue",
  "IndianRed",
  "BurlyWood",
  "DarkCyan",
  "Plum",
  "MediumTurquoise",
  "LightCoral",
  "RebeccaPurple",
];

const GRAPH_DATASET_COLORS_LENGTH = GRAPH_DATASET_COLORS.length;

export const getGraphColor = (index) =>
  GRAPH_DATASET_COLORS[index % GRAPH_DATASET_COLORS_LENGTH];

export const PIE_CHART_DATASET_COLORS = [
  "#74DDE5",
  "#FAC86B",
  "#6BDAAE",
  "#8F59EF",
  "#FD8ADC",
  "#FF9D66",
  "#001489",
  "#006DFA",
  "#99CDFF",
  "#CCE4FF",
  "#E1E3EA",
];

const PIE_CHART_DATASET_COLORS_LENGTH = PIE_CHART_DATASET_COLORS.length;

export const getPieChartColor = (index) =>
  PIE_CHART_DATASET_COLORS[index % PIE_CHART_DATASET_COLORS_LENGTH];

export const GRAPH_COLOR_SCALE = [
  "#032132",
  "#16439B",
  "#1770ED",
  "#4EA5EB",
  "#ACD4F5",
  "#CCE3F8",
  "#F9F2BF",
  "#FDE273",
  "#E8B127",
  "#A77109",
  "#663610",
  "#331701",
];
