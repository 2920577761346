import React, { useContext } from "react";
import { get } from "lodash";
import { ArrowRightIcon, DashboardIcon, MapIcon } from "evergreen-ui";
import {
  Button,
  Heading,
  IconButton,
  Pane,
  Paragraph,
  EmptyCard,
  ConfigureButtons,
} from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { multiply } from "helpers/math";
import {
  formatCurrency,
  formatCurrencyDifference,
} from "helpers/formatCurrency";
import analytics from "helpers/analytics";
import {
  DivisionSummaryHeader,
  DivisionSummaryRow,
  DivisionSummaryTotal,
} from "./DivisionSummaryRow";

function getDivisionAggregates(division) {
  return division.lineItems.reduce(
    (division, lineItem) => ({
      ...division,
      budget: division.budget + lineItem.budgetAmount,
      originalBudget: division.originalBudget + lineItem.originalBudgetAmount,
      requested: division.requested + lineItem.requestedToDateAmount,
    }),
    {
      name: division.name,
      budget: 0,
      originalBudget: 0,
      requested: 0,
    }
  );
}

function Info({ project, theme }) {
  const infoGroupStyling = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const infoLabelStyling = {
    fontWeight: 400,
    color: theme.colors.gray700,
  };

  const infoValueStyling = {
    size: 600,
    fontWeight: 400,
  };

  const percentUsed = get(project, "contingencySegments.all.percentUsed", 0);

  return (
    <Pane
      backgroundColor={theme.colors.blue25}
      borderRadius={majorScale(1)}
      display="flex"
      justifyContent="space-between"
      width="100%"
      height={72}
    >
      <Pane {...infoGroupStyling} paddingLeft={16}>
        <Paragraph {...infoLabelStyling}>Current Budget</Paragraph>
        <Heading {...infoValueStyling}>
          {formatCurrency(project.amount)}
        </Heading>
      </Pane>
      <Pane {...infoGroupStyling}>
        <Paragraph {...infoLabelStyling}>Budget Change</Paragraph>
        <Heading {...infoValueStyling}>
          {formatCurrencyDifference(project.amount, project.originalAmount)}
        </Heading>
      </Pane>
      <Pane {...infoGroupStyling} paddingRight={16}>
        <Paragraph {...infoLabelStyling}>Contingency Used</Paragraph>
        <Heading {...infoValueStyling}>{multiply(percentUsed, 100)}%</Heading>
      </Pane>
    </Pane>
  );
}

function DivisionSummary({ divisions, theme }) {
  const divisionAggregates = divisions.map(getDivisionAggregates);
  return (
    <Pane width="100%" alignItems="center" justifyContent="space-between">
      <DivisionSummaryHeader theme={theme} />
      <Pane maxHeight={96} overflowY="scroll" width="100%">
        {divisionAggregates.map((division, index) => (
          <DivisionSummaryRow
            key={`${division.id}-${index}`}
            division={division}
            theme={theme}
          />
        ))}
      </Pane>
      <DivisionSummaryTotal divisions={divisionAggregates} theme={theme} />
    </Pane>
  );
}

export const BUDGET_CONFIGURATION_SETTINGS = {
  i: "budget",
  x: 2,
  y: 1,
  w: 1,
  h: 1,
  disabled: false,
};

export function Budget({
  history,
  isConfigurable,
  isDisabled,
  project,
  cards,
  setCards,
  name,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);
  const canEditBudget = hasPermission(PERMISSION_ACTION.EDIT_BUDGET);
  const isMissingBudget = project.divisions.length === 0;

  if (isMissingBudget) {
    return (
      <EmptyCard
        name="Budget"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
        icon={<DashboardIcon color="muted" />}
      >
        {canEditBudget ? (
          <React.Fragment>
            <Paragraph paddingBottom={majorScale(1)}>
              This project does not have a budget yet.
            </Paragraph>
            <Button
              marginTop={8}
              appearance="primary"
              onClick={() => history.push(`/projects/${project.id}/budget`)}
            >
              Add Budget
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MapIcon size={majorScale(3)} />
            <Paragraph marginTop={majorScale(2)}>
              This project does not have a budget yet.
            </Paragraph>
            <Paragraph>You do not have access to add a budget.</Paragraph>
            <Paragraph>Please contact your admin.</Paragraph>
          </React.Fragment>
        )}
      </EmptyCard>
    );
  }

  return (
    <Pane
      width="100%"
      height="100%"
      position="relative"
      padding={majorScale(2)}
    >
      <Pane
        id="header"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            lineHeight={1.2}
            fontWeight={500}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Budget
          </Heading>
          <DashboardIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => {
              analytics.track("project dashboard navigate to budget");
              history.push(`/projects/${project.id}/budget`);
            }}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
            marginLeft="auto"
          />
        )}
      </Pane>
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={minorScale(3)}
      >
        <Info project={project} theme={theme} />
      </Pane>
      <DivisionSummary divisions={project.divisions} theme={theme} />
    </Pane>
  );
}
