import {
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useFeatureFlags } from "FeatureFlags";
import { EditTableViews } from "components/containers";
import {
  ActionItemBlankSlate,
  BudgetForm,
  IssuesInfoModal,
  IssuesViewName,
} from "components/templates";
import {
  Banner,
  Button,
  Link,
  Loadable,
  Pane,
  Tab,
  Tablist,
  Text,
  Paragraph,
} from "components/materials";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  currencyColumnDefaults,
  enumColumnDefaults,
  numberColumnDefaults,
  percentColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
  listColumnDefaults,
} from "components/materials/FastDataTable";
import {
  escapeRegExp,
  get,
  groupBy,
  flatMap,
  intersection,
  intersectionBy,
  isEqual,
  mapValues,
  minBy,
  uniq,
  uniqBy,
} from "lodash";
import { UserContext } from "helpers/behaviors";
import {
  getIssuesRowState,
  getIssuesTableValue,
  getIssuesTableValueFormatter,
  GUIDING_EXPLANATION_SCOPES,
} from "helpers/issues";
import { add, divide, subtract, sumBy } from "helpers/math";
import {
  formatCategories,
  getLineItemPosition,
  getSuperLineItemAggregates,
} from "helpers/lineItemTableHelpers";
import { stringComparator } from "helpers/comparators";
import { getDefaultAggregate } from "helpers/tableAggregateHelpers";
import { majorScale, minorScale } from "helpers/utilities";
import { formatDate, TRIM_DATE_FORMAT } from "helpers/dateHelpers";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import {
  DRAW_STATE,
  MASTER_FORMAT_DIVISION,
  PERMISSION_ACTION,
} from "helpers/enums";
import t from "helpers/translate";
import isBlank from "helpers/isBlank";
import {
  orderedLineItemCategories,
  orderedLineItemTypes,
} from "../../templates/LineItemSettings";
import {
  BudgetLineItemSlideout,
  BudgetSuperLineItemSlideout,
} from "./BudgetLineItemSlideout";
import { BudgetVisualizations } from "./BudgetVisualizations";
import {
  BUDGET_QUERY,
  ISSUES_SUBSCRIPTION,
  UPDATE_USER_VIEW_CONFIG,
} from "./graphql-queries";
import {
  adjustmentsOverTimeViewConfig,
  anticipatedCostViewConfig,
  bySummaryLineItemViewConfig,
  currentBudgetViewConfig,
  issuesViewConfig,
  originalBudgetVarianceViewConfig,
  requestedOverTimeViewConfig,
} from "./viewConfigs";

function getDefaultViews({
  hasAgreements,
  hasSuperLineItems,
  hasRulesRedesign,
  showSuperLineItems,
  lineItems,
}) {
  const lineItemIssues = uniqBy(
    flatMap(lineItems, ({ issues }) => issues || []),
    ({ lineItemId, name }) => `${lineItemId}${name}`
  );

  return [
    {
      config: currentBudgetViewConfig(hasRulesRedesign, lineItemIssues),
      isDefault: true,
      name: "Current Budget",
    },
    ...(hasRulesRedesign
      ? [
          {
            config: issuesViewConfig(lineItemIssues),
            isDefault: true,
            name: "Issues",
            formattedName: <IssuesViewName issues={lineItemIssues} />,
          },
        ]
      : []),
    ...(hasAgreements
      ? [
          {
            config: anticipatedCostViewConfig(),
            isDefault: true,
            name: "Anticipated Cost Report",
          },
          {
            config: originalBudgetVarianceViewConfig(),
            isDefault: true,
            name: "Original Budget Variance",
          },
        ]
      : []),
    {
      config: adjustmentsOverTimeViewConfig(),
      isDefault: true,
      name: "Adjustments Over Time",
    },
    {
      config: requestedOverTimeViewConfig(),
      isDefault: true,
      name: "Requested Over Time",
    },
    ...(hasSuperLineItems && !showSuperLineItems
      ? [
          {
            config: bySummaryLineItemViewConfig(),
            isDefault: true,
            name: "By Summary Line Item",
          },
        ]
      : []),
  ];
}

function getColumns({
  draws,
  hasAgreements,
  hasCostToAgreements,
  hasEnhancedLineItemReporting,
  hasInspection,
  hasSuperLineItems,
  hasRulesRedesign,
  projectId,
  projectAcres,
  projectSquareFeet,
  setIssueItemForModal,
  showSuperLineItems,
}) {
  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      aggregate: (lineItems) => getDefaultAggregate(lineItems, "name"),
      header: "Line Item",
      id: "lineItem",
      // category not needed - primary column
      value: (lineItem) => lineItem.name,
    },
    {
      ...stringColumnDefaults,
      aggregate: (lineItems) => getDefaultAggregate(lineItems, "division.name"),
      groupable: true,
      header: "Division",
      id: "division",
      category: "General Budget",
      value: (lineItem) => lineItem.division.name,
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Summary Line Item",
      hidden: !hasSuperLineItems || showSuperLineItems,
      id: "superLineItem",
      category: "General Budget",
      value: (lineItem) =>
        `${lineItem.division.name} - ${
          lineItem.superLineItem?.name || lineItem.name
        }`,
      valueExporter: (_value, lineItem) => lineItem.superLineItem?.name,
      valueFormatter: (value, lineItem, { isGroupedValue }) => {
        if (isGroupedValue) {
          return value;
        }
        return lineItem.superLineItem?.name;
      },
      width: 120,
    },
    {
      ...listColumnDefaults,
      header: "Line Items",
      hidden: !hasSuperLineItems || !showSuperLineItems,
      id: "lineItems",
      category: "General Budget",
      value: (lineItem) => {
        const lineItemNames = lineItem.lineItems
          ? lineItem.lineItems.map(({ name }) => name)
          : [lineItem.name];

        return lineItemNames.join(", ");
      },
      width: 200,
    },
    {
      ...numberColumnDefaults,
      header: "Position",
      hidden: true,
      id: "position",
      // category not needed - always hidden
      value: getLineItemPosition,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "originalBudgetAmount"),
      header: "Original Budget",
      id: "originalBudget",
      category: "General Budget",
      value: (lineItem) => lineItem.originalBudgetAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          add(lineItem.adjustmentsAmount, lineItem.previousAdjustmentsAmount)
        ),
      header: "Adjustments",
      id: "adjustments",
      category: "General Budget",
      value: (lineItem) =>
        add(lineItem.adjustmentsAmount, lineItem.previousAdjustmentsAmount),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "budgetAmount"),
      header: "Current Budget",
      id: "currentBudget",
      category: "General Budget",
      value: (lineItem) => lineItem.budgetAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "grossRequestedToDateAmount"),
      header: "Amount Requested (Gross)",
      id: "grossRequested",
      category: "General Budget",
      value: (lineItem) => lineItem.grossRequestedToDateAmount,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "retainageToDateAmount"),
      header: "Retainage",
      id: "retainage",
      category: "General Budget",
      value: (lineItem) => lineItem.retainageToDateAmount || 0,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "requestedToDateAmount"),
      header: "Amount Requested (Net)",
      id: "requested",
      category: "General Budget",
      value: (lineItem) => lineItem.requestedToDateAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "balanceToFundAmount"),
      header: "Balance To Fund",
      id: "balanceToFund",
      category: "Calculations",
      value: (lineItem) => lineItem.balanceToFundAmount,
    },
    {
      ...percentColumnDefaults,
      aggregate: (lineItems) =>
        divide(
          sumBy(lineItems, "balanceToFundAmount"),
          sumBy(lineItems, "budgetAmount")
        ),
      header: "% Remaining (Net)",
      id: "percentRemaining",
      category: "Calculations",
      value: (lineItem) =>
        lineItem.types?.includes("CONTINGENCY")
          ? divide(
              lineItem.balanceToFundAmount,
              add(
                lineItem.originalBudgetAmount,
                lineItem.untrackedContingencyAmount
              )
            )
          : divide(lineItem.balanceToFundAmount, lineItem.budgetAmount),
    },
    {
      ...percentColumnDefaults,
      aggregate: (lineItems) =>
        divide(
          subtract(
            sumBy(lineItems, "balanceToFundAmount"),
            sumBy(lineItems, "retainageToDateAmount")
          ),
          sumBy(lineItems, "budgetAmount")
        ),
      header: "% Remaining (Gross)",
      id: "percentRemainingGross",
      category: "Calculations",
      value: (lineItem) =>
        lineItem.types?.includes("CONTINGENCY")
          ? divide(
              subtract(
                lineItem.balanceToFundAmount,
                lineItem.retainageToDateAmount
              ),
              add(
                lineItem.originalBudgetAmount,
                lineItem.untrackedContingencyAmount
              )
            )
          : divide(
              subtract(
                lineItem.balanceToFundAmount,
                lineItem.retainageToDateAmount
              ),
              lineItem.budgetAmount
            ),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "totalCommitmentsAmount"),
      header: "Total Commitments",
      hidden: !hasAgreements,
      id: "committed",
      category: "Commitments",
      tooltip: hasCostToAgreements
        ? t("budgetPage.totalCommitments.withOutOfContract")
        : t("budgetPage.totalCommitments.default"),
      value: (lineItem) => lineItem.totalCommitmentsAmount,
      markColumnInDefaultView: true,
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.budgetAmount, lineItem.totalCommitmentsAmount)
        ),
      header: "Total Uncommitted",
      hidden: !hasAgreements,
      id: "uncommitted",
      category: "Commitments",
      tooltip: hasCostToAgreements
        ? t("budgetPage.totalUncommitted.withOutOfContract")
        : t("budgetPage.totalUncommitted.default"),
      value: (lineItem) =>
        subtract(lineItem.budgetAmount, lineItem.totalCommitmentsAmount),
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "exposedAmount"),
      header: "PCOs & Exposures",
      hidden: !hasAgreements,
      id: "exposed",
      category: "Commitments",
      tooltip: t("budgetPage.exposedAmount"),
      value: (lineItem) => lineItem.exposedAmount,
      width: 145,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "pcoAmount"),
      header: "PCOs",
      hidden: !hasAgreements,
      id: "pcoAmount",
      category: "Commitments",
      value: (lineItem) => lineItem.pcoAmount,
      width: 110,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "exposureAmount"),
      header: "Exposures",
      hidden: !hasAgreements,
      id: "exposureAmount",
      category: "Commitments",
      value: (lineItem) => lineItem.exposureAmount,
      width: 110,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "outOfContractAmount"),
      header: "Out Of Contract",
      hidden: !hasCostToAgreements,
      id: "outOfContract",
      category: "Commitments",
      tooltip: t("budgetPage.outOfContract"),
      value: (lineItem) => lineItem.outOfContractAmount,
      width: 130,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "committedAmount"),
      header: "Executed Agreements",
      hidden: !hasCostToAgreements,
      id: "executedAgreements",
      category: "Commitments",
      tooltip: t("budgetPage.executedAgreements"),
      value: (lineItem) => lineItem.committedAmount,
      width: 162,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "totalAgreementsAmount"),
      header: "Total Agreements",
      hidden: !hasAgreements,
      // keep legacy id to maintain legacy saved views
      id: "legacyAnticipated",
      category: "Commitments",
      tooltip: t("budgetPage.totalAgreements"),
      value: (lineItem) => lineItem.totalAgreementsAmount,
      width: 140,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.budgetAmount, lineItem.totalAgreementsAmount)
        ),
      header: "Agreement Variance",
      hidden: !hasAgreements,
      // keep legacy id to maintain legacy saved views
      id: "legacyAnticipatedBalanceToFund",
      category: "Commitments",
      tooltip: t("budgetPage.agreementVariance"),
      value: (lineItem) =>
        subtract(lineItem.budgetAmount, lineItem.totalAgreementsAmount),
      width: 155,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "exposureForecast"),
      header: "Exposure Forecast",
      hidden: !hasAgreements,
      // keep legacy id to maintain legacy saved views
      id: "anticipated",
      category: "Commitments",
      tooltip: hasCostToAgreements
        ? t("budgetPage.exposureForecast.withOutOfContract")
        : t("budgetPage.exposureForecast.default"),
      value: (lineItem) => lineItem.exposureForecast,
      width: 145,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.budgetAmount, lineItem.exposureForecast)
        ),
      header: "Exposure Overages",
      hidden: !hasAgreements,
      // keep legacy id to maintain legacy saved views
      id: "anticipatedBalanceToFund",
      category: "Commitments",
      tooltip: t("budgetPage.exposureOverages"),
      value: (lineItem) =>
        subtract(lineItem.budgetAmount, lineItem.exposureForecast),
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "costEstimate"),
      header: "Cost Estimate",
      hidden: !hasAgreements,
      // keep legacy id to maintain legacy saved views
      id: "anticipatedExpenditures",
      tooltip: t("budgetPage.costEstimate"),
      category: "Commitments",
      value: (lineItem) => lineItem.costEstimate,
      markColumnInDefaultView: true,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.budgetAmount, lineItem.costEstimate)
        ),
      header: "Current Budget Variance",
      hidden: !hasAgreements,
      // keep legacy id to maintain legacy saved views
      id: "expendituresOverages",
      category: "Commitments",
      tooltip: t("budgetPage.currentBudgetVariance"),
      value: (lineItem) =>
        subtract(lineItem.budgetAmount, lineItem.costEstimate),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.originalBudgetAmount, lineItem.costEstimate)
        ),
      header: "Original Budget Variance",
      hidden: !hasAgreements,
      id: "originalBudgetVariance",
      category: "Commitments",
      tooltip: t("budgetPage.originalBudgetVariance"),
      value: (lineItem) =>
        subtract(lineItem.originalBudgetAmount, lineItem.costEstimate),
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(MASTER_FORMAT_DIVISION)
        .map((division) => t(`masterFormatDivisions.${division}`))
        .sort((a, b) => stringComparator(a, b))
        .concat(null),
      aggregate: (lineItems) =>
        getDefaultAggregate(lineItems, ({ masterFormatDivision }) =>
          masterFormatDivision
            ? t(`masterFormatDivisions.${masterFormatDivision}`)
            : null
        ),
      groupable: true,
      header: "Master Format Division",
      hidden: !hasEnhancedLineItemReporting || showSuperLineItems,
      id: "masterFormatDivision",
      category: "Line Item Settings",
      value: ({ masterFormatDivision }) =>
        masterFormatDivision
          ? t(`masterFormatDivisions.${masterFormatDivision}`)
          : null,
      width: 200,
    },
    {
      ...stringColumnDefaults,
      header: "Line Item #",
      hidden: showSuperLineItems,
      id: "lineItemNumber",
      category: "Line Item Settings",
      groupable: true,
      value: (lineItem) => lineItem.number,
      width: 120,
    },
    {
      ...enumColumnDefaults,
      enumValues: orderedLineItemTypes
        .concat(null)
        .map((type) => t(`lineItemTypes.${type}`)),
      aggregate: (lineItems) => {
        const allTypes = lineItems.map((lineItem) => {
          const typeValue = intersection(orderedLineItemTypes, lineItem.types);
          if (typeValue.length === 0) return null;
          return t(`lineItemTypes.${typeValue}`);
        });
        const types = uniq(allTypes);
        if (types.length > 1) return "(mixed)";
        return t(`lineItemTypes.${types[0]}`);
      },
      groupable: true,
      header: "Type",
      hidden: showSuperLineItems,
      id: "type",
      category: "Line Item Settings",
      value: (lineItem) => {
        const [type] = intersection(orderedLineItemTypes, lineItem.types);
        if (!type) return "(neither)";
        return t(`lineItemTypes.${type}`);
      },
    },
    {
      ...enumColumnDefaults,
      enumValues: orderedLineItemCategories.map((type) =>
        t(`lineItemTypes.${type}`)
      ),
      aggregate: (lineItems) => {
        const allCategories = lineItems.reduce((acc, lineItem) => {
          const categories = intersection(
            orderedLineItemCategories,
            lineItem.types
          );
          return [...acc, categories];
        }, []);
        const isMixed = allCategories.some((categories) => {
          return !isEqual(allCategories[0], categories);
        });
        if (isMixed) return "(mixed)";
        return formatCategories(allCategories[0]);
      },
      // this is a tweaked version of the default enum filterStrategy
      // this column is a bit different than other enum columns,
      // as the line item is not limited to a single category
      filterStrategy: (value, filterConfig) => {
        const { enum: filterConfigEnum, __isSearch, input } = filterConfig;
        const isEmptyValue = isBlank(value);
        if (__isSearch === true) {
          return isBlank(input)
            ? true
            : !isEmptyValue && new RegExp(escapeRegExp(input), "i").test(value);
        }
        if (filterConfigEnum) {
          return isEmptyValue
            ? false
            : intersection(value.split(", "), filterConfigEnum).length > 0;
        }
        return true;
      },
      groupable: true,
      header: "Categories",
      hidden: showSuperLineItems,
      id: "categories",
      category: "Line Item Settings",
      value: (lineItem) => {
        const categories = intersection(
          orderedLineItemCategories,
          lineItem.types
        );
        return formatCategories(categories);
      },
    },
    {
      ...listColumnDefaults,
      id: "issues",
      category: "Line Item Settings",
      groupable: true,
      header: "Issues",
      hidden: !hasRulesRedesign,
      value: getIssuesTableValue,
      valueFormatter: (lineItemIssues, item, config) => {
        function onClick() {
          setIssueItemForModal(item);
        }
        return getIssuesTableValueFormatter(
          lineItemIssues,
          item,
          config,
          onClick
        );
      },
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "originalBudgetAmount"), projectSquareFeet),
      header: "Original Budget / Area (sqft)",
      id: "originalBudgetBySquareFeet",
      category: "Calculations",
      value: (lineItem) =>
        divide(lineItem.originalBudgetAmount, projectSquareFeet),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "budgetAmount"), projectSquareFeet),
      header: "Current Budget / Area (sqft)",
      id: "currentBudgetBySquareFeet",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.budgetAmount, projectSquareFeet),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "requestedToDateAmount"), projectSquareFeet),
      header: "Amount Requested (Net) / Area (sqft)",
      id: "requestedAmountBySquareFeet",
      category: "Calculations",
      value: (lineItem) =>
        divide(lineItem.requestedToDateAmount, projectSquareFeet),
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(
          sumBy(lineItems, "grossRequestedToDateAmount"),
          projectSquareFeet
        ),
      header: "Amount Requested (Gross) / Area (sqft)",
      id: "grossRequestedAmountBySquareFeet",
      category: "Calculations",
      value: (lineItem) =>
        divide(lineItem.grossRequestedToDateAmount, projectSquareFeet),
      width: 130,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "originalBudgetAmount"), projectAcres),
      header: "Original Budget / Acre",
      id: "originalBudgetByAcres",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.originalBudgetAmount, projectAcres),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "budgetAmount"), projectAcres),
      header: "Current Budget / Acre",
      id: "currentBudgetByAcres",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.budgetAmount, projectAcres),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "requestedToDateAmount"), projectAcres),
      header: "Amount Requested (Net) / Acre",
      id: "requestedAmountByAcres",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.requestedToDateAmount, projectAcres),
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "grossRequestedToDateAmount"), projectAcres),
      header: "Amount Requested (Gross) / Acre",
      id: "grossRequestedAmountByAcres",
      category: "Calculations",
      value: (lineItem) =>
        divide(lineItem.grossRequestedToDateAmount, projectAcres),
      width: 125,
    },
  ].concat(
    draws.reduce((acc, draw) => {
      const drawHeaderState = t(`drawStates.${draw.state}`);
      const drawHeaderDate =
        draw.state === DRAW_STATE.FUNDED
          ? formatDate(draw.fundedDate, "", TRIM_DATE_FORMAT)
          : "";

      const headerGroupProps = {
        headerGrouping: draw.name,
        headerRepeated: "Draw",
        headerGroupFormatted: (
          <Fragment>
            <Link
              purpose="budget page draw open"
              size={300}
              to={`/projects/${projectId}/draws/${draw.id}`}
            >
              {draw.name}
            </Link>
            <Paragraph
              color="muted"
              size={300}
              fontWeight={500}
              textTransform="uppercase"
              marginTop={minorScale(1)}
            >
              {`${drawHeaderState} ${drawHeaderDate}`}
            </Paragraph>
          </Fragment>
        ),
      };

      return acc.concat([
        {
          ...currencyColumnDefaults,
          aggregate: (lineItems) =>
            sumBy(
              intersectionBy(draw.lineItems, lineItems, "id"),
              "adjustmentsAmount"
            ),
          header: "Adjustments",
          id: `drawAdjustment:${draw.id}`,
          category: "By Draw",
          idRepeated: "drawAdjustment",
          testId: `drawAdjustment:${draw.name}`,
          value: (lineItem) =>
            get(
              draw.lineItems.find((dli) => dli.id === lineItem.id),
              "adjustmentsAmount"
            ),
          ...headerGroupProps,
        },
        {
          ...currencyColumnDefaults,
          aggregate: (lineItems) =>
            sumBy(
              intersectionBy(draw.lineItems, lineItems, "id"),
              "grossRequestedAmount"
            ),
          header: "Amount Requested (Gross)",
          id: `drawGrossRequested:${draw.id}`,
          category: "By Draw",
          idRepeated: "drawGrossRequested",
          testId: `drawGrossRequested:${draw.name}`,
          value: (lineItem) =>
            get(
              draw.lineItems.find((dli) => dli.id === lineItem.id),
              "grossRequestedAmount"
            ),
          width: 125,
          ...headerGroupProps,
        },
        {
          ...currencyColumnDefaults,
          aggregate: (lineItems) =>
            sumBy(
              intersectionBy(draw.lineItems, lineItems, "id"),
              "retainageAmount"
            ),
          header: "Retainage",
          id: `drawRetainage:${draw.id}`,
          category: "By Draw",
          idRepeated: "drawRetainage",
          testId: `drawRetainage:${draw.name}`,
          value: (lineItem) =>
            get(
              draw.lineItems.find((dli) => dli.id === lineItem.id),
              "retainageAmount"
            ),
          ...headerGroupProps,
        },
        {
          ...currencyColumnDefaults,
          aggregate: (lineItems) =>
            sumBy(
              intersectionBy(draw.lineItems, lineItems, "id"),
              "requestedAmount"
            ),
          header: "Amount Requested (Net)",
          id: `drawRequested:${draw.id}`,
          category: "By Draw",
          idRepeated: "drawRequested",
          testId: `drawRequested:${draw.name}`,
          value: (lineItem) =>
            get(
              draw.lineItems.find((dli) => dli.id === lineItem.id),
              "requestedAmount"
            ),
          ...headerGroupProps,
        },
        {
          ...percentColumnDefaults,
          header: "Inspection % Complete",
          hidden: !hasInspection || showSuperLineItems,
          id: `drawInspectionPercent:${draw.id}`,
          category: "By Draw",
          idRepeated: "drawInspectionPercent",
          testId: `drawInspectionPercent:${draw.name}`,
          value: (lineItem) =>
            get(
              draw.lineItems.find((dli) => dli.id === lineItem.id),
              "inspectionPercentComplete"
            ),
          ...headerGroupProps,
        },
      ]);
    }, [])
  );
}

function BudgetTable({
  history,
  handleOpenSlideout,
  project,
  setShowVisualizations,
  showVisualizations,
  showSuperLineItems,
}) {
  const { hasPermission, organizationId } = useContext(UserContext);
  const [editBudget, setEditBudget] = useState(false);
  const [showEditBudgetAnimation, setShowEditBudgetAnimation] = useState(false);
  const [issueItemForModal, setIssueItemForModal] = useState(null);
  const hasEditBudget = hasPermission(PERMISSION_ACTION.EDIT_BUDGET);
  const hasAgreements = hasPermission(PERMISSION_ACTION.AGREEMENT_MANAGEMENT);
  const hasCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );
  const hasSuperLineItems = hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS);
  const hasInspection = hasPermission(
    PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW
  );
  const hasEnhancedLineItemReporting = hasPermission(
    PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING
  );
  const hasRulesRedesign = hasPermission(
    PERMISSION_ACTION.RULES_REDESIGN_CLERICAL
  );

  const featureFlags = useFeatureFlags();
  const useNewDashboard = featureFlags.isEnabled(
    "use-redesigned-project-dashboard"
  );

  const { superLineItems, superDraws } = useMemo(
    () =>
      prepareSuperLineItemsAndDraws(
        project.lineItems,
        project.draws,
        hasPermission
      ),
    [project.lineItems, project.draws, hasPermission]
  );

  const draws = showSuperLineItems ? superDraws : project.draws;

  const defaultViews = useMemo(
    () =>
      getDefaultViews({
        hasAgreements,
        hasSuperLineItems,
        hasRulesRedesign,
        showSuperLineItems,
        lineItems: project.lineItems,
      }),
    [
      hasAgreements,
      hasSuperLineItems,
      hasRulesRedesign,
      showSuperLineItems,
      project,
    ]
  );

  const columns = useMemo(
    () =>
      getColumns({
        draws,
        hasAgreements,
        hasCostToAgreements,
        hasEnhancedLineItemReporting,
        hasInspection,
        hasSuperLineItems,
        hasRulesRedesign,
        projectId: project.id,
        projectAcres: project.acres,
        projectSquareFeet: project.squareFeet,
        setIssueItemForModal,
        showSuperLineItems,
      }),
    [
      draws,
      project.id,
      project.acres,
      project.squareFeet,
      hasAgreements,
      hasCostToAgreements,
      hasEnhancedLineItemReporting,
      hasInspection,
      hasRulesRedesign,
      hasSuperLineItems,
      setIssueItemForModal,
      showSuperLineItems,
    ]
  );

  const mostRecentDraw = get(project.draws, "0");

  function closeEditBudget() {
    setShowEditBudgetAnimation(false);
    setTimeout(() => setEditBudget(false), 350);
  }

  const tableProps = showSuperLineItems
    ? {
        tableName: "SuperBudgetTable",
        items: superLineItems,
      }
    : {
        tableName: "BudgetTable",
        items: project.lineItems,
      };

  return (
    <Fragment>
      {mostRecentDraw && !hasEditBudget && (
        <Banner
          border
          icon="infoSignIcon"
          mainText={t("budgetPage.bannerMain")}
          secondaryText={t("budgetPage.bannerSecondary")}
        >
          <Link
            marginLeft={minorScale(1)}
            to={`/projects/${project.id}/draws/${mostRecentDraw.id}`}
          >
            {t("budgetPage.bannerLink", { name: mostRecentDraw.name })}
          </Link>
        </Banner>
      )}
      <EditTableViews
        canManagePublicViews={hasPermission(PERMISSION_ACTION.SAVE_TABLE_VIEWS)}
        config={getSearchByKey(history, "table")}
        organizationIdToScopeViews={organizationId}
        defaultViews={defaultViews}
        tableName={tableProps.tableName}
      >
        {(propsEditTableViews) => (
          <Fragment>
            <FastDataTable
              columns={columns}
              controls={(propsControls) => {
                return (
                  <FastDataTableAdvancedControls
                    {...propsControls}
                    {...propsEditTableViews}
                    rightControls={
                      hasEditBudget && (
                        <Button onClick={() => setEditBudget(true)}>
                          Edit Original Budget
                        </Button>
                      )
                    }
                    disable={[FastDataTableDownloadDocuments]}
                    searchPlaceholder="Search Line Items..."
                  >
                    {!showVisualizations && !useNewDashboard && (
                      <Button
                        onClick={() => setShowVisualizations(true)}
                        marginBottom={-majorScale(2)}
                      >
                        Show Budget Progress Graphs
                      </Button>
                    )}
                  </FastDataTableAdvancedControls>
                );
              }}
              items={tableProps.items}
              onClickRow={handleOpenSlideout}
              onSerialize={(table) => mergeSearch(history, { table })}
              serialized={
                getSearchByKey(history, "table") ||
                get(propsEditTableViews, "views.0.config")
              }
              getRowState={(lineItem) => {
                if (hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL)) {
                  return getIssuesRowState(lineItem.issues);
                }
                return undefined;
              }}
              empty={
                tableProps.items.length === 0 ? (
                  <ActionItemBlankSlate
                    content={
                      <Text style={{ textAlign: "center" }}>
                        You don&apos;t have a budget yet{" "}
                        {hasEditBudget ? (
                          <Text>
                            <br />
                            <br />
                            <Link onClick={() => setEditBudget(true)}>
                              Enter information directly into Rabbet
                            </Link>
                            <br />
                            <br />
                            <Link
                              onClick={() =>
                                history.push(
                                  `/projects/${project.id}/budget_upload`
                                )
                              }
                            >
                              Upload budget from an existing file
                            </Link>
                          </Text>
                        ) : (
                          <Link href="mailto:help@rabbet.com">
                            Contact our Partner Success team to help get you
                            started.
                          </Link>
                        )}
                      </Text>
                    }
                  />
                ) : (
                  t("fastDataTable.empty")
                )
              }
              footerTotals
            />
            {editBudget && (
              <BudgetForm
                project={project}
                setShowEditBudgetAnimation={setShowEditBudgetAnimation}
                closeEditBudget={closeEditBudget}
                showEditBudgetAnimation={showEditBudgetAnimation}
                refetchQueries={[
                  { query: BUDGET_QUERY, variables: { projectId: project.id } },
                ]}
              />
            )}
          </Fragment>
        )}
      </EditTableViews>
      {issueItemForModal && (
        <IssuesInfoModal
          issueItem={issueItemForModal}
          scope={GUIDING_EXPLANATION_SCOPES.BUDGET_LINE_ITEM}
          onClose={() => setIssueItemForModal(null)}
        />
      )}
    </Fragment>
  );
}

export function BudgetPage({ history, match }) {
  const { lineItemId, projectId, superLineItemId } = match.params;
  const { hasPermission, user } = useContext(UserContext);
  const { showBudgetCharts, budgetChartView } = user;
  const [showVisualizations, setShowVisualizations] = useState(
    showBudgetCharts
  );
  const [selectedView, setSelectedView] = useState(budgetChartView);

  const [updateUserViewConfig] = useMutation(UPDATE_USER_VIEW_CONFIG);

  useEffect(() => {
    updateUserViewConfig({
      variables: {
        showBudgetCharts: showVisualizations,
        budgetChartView: selectedView,
      },
    });
  }, [showVisualizations, selectedView, updateUserViewConfig]);

  const baseUrl = `/projects/${projectId}/budget`;

  const handleOpenSlideout = (lineItem) => {
    history.push(
      `${baseUrl}/${
        lineItem.isSuperLineItem ? "summary_line_items" : "line_items"
      }/${lineItem.id}${history.location.search}`
    );
  };

  const handleCloseSlideout = () => {
    if (match.params.documentId) return;
    history.push(`${baseUrl}${history.location.search}`);
  };

  const viewConfig = getSearchByKey(history, "view");
  const [showSuperLineItems, setShowSuperLineItems] = useState(
    viewConfig === "summary"
  );

  const initialUpdate = useRef(true);
  useEffect(() => {
    if (initialUpdate.current === true) {
      initialUpdate.current = false;
    } else {
      mergeSearch(history, {
        view: showSuperLineItems ? "summary" : "detailed",
      });
    }
  }, [history, showSuperLineItems]);

  const { data, loading, subscribeToMore } = useQuery(BUDGET_QUERY, {
    variables: { projectId },
  });

  useEffect(() => {
    return subscribeToMore({
      document: ISSUES_SUBSCRIPTION,
      variables: { projectId },
    });
  }, [projectId, subscribeToMore]);

  if (loading) return <Loadable />;

  const showSuperLineItemsToggle =
    hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS) &&
    (data?.project?.lineItems || []).some(
      (lineItem) => !!lineItem.superLineItem
    );

  return (
    <Fragment>
      {showVisualizations && (
        <BudgetVisualizations
          project={data.project}
          setShowVisualizations={setShowVisualizations}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
        />
      )}
      {showSuperLineItemsToggle && (
        <Pane display="flex" alignItems="center" marginY={majorScale(2)}>
          <Tablist marginLeft={minorScale(3)} marginRight={majorScale(3)}>
            <Tab
              isSelected={!showSuperLineItems}
              onSelect={() => setShowSuperLineItems(false)}
            >
              View Detailed Line Items
            </Tab>
            <Tab
              isSelected={showSuperLineItems}
              onSelect={() => setShowSuperLineItems(true)}
            >
              View Summary Line Items
            </Tab>
          </Tablist>
        </Pane>
      )}
      <BudgetTable
        history={history}
        handleOpenSlideout={handleOpenSlideout}
        project={data.project}
        showSuperLineItems={showSuperLineItems}
        showVisualizations={showVisualizations}
        setShowVisualizations={setShowVisualizations}
      />
      {lineItemId && (
        <BudgetLineItemSlideout
          history={history}
          match={match}
          onCloseComplete={handleCloseSlideout}
        />
      )}
      {superLineItemId && (
        <BudgetSuperLineItemSlideout
          history={history}
          match={match}
          onCloseComplete={handleCloseSlideout}
        />
      )}
    </Fragment>
  );
}

function prepareSuperLineItemsAndDraws(budgetlineItems, draws, hasPermission) {
  if (!hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS))
    return { superLineItems: [], superDraws: [] };

  const superLineItems = prepareSuperLineItems(budgetlineItems);
  const superDraws = prepareSuperDraws(budgetlineItems, draws);

  return { superLineItems, superDraws };
}

function prepareSuperDraws(budgetlineItems, draws) {
  const lineItemsBySuperLineItem = groupBy(budgetlineItems, "superLineItem.id");

  return draws.map((draw) => {
    const { lineItems: drawLineItems } = draw;
    // for easy lookup
    const drawLineItemsById = drawLineItems.reduce(
      (acc, lineItem) => ({
        ...acc,
        [lineItem.id]: lineItem,
      }),
      {}
    );

    // get the draw line items grouped by super line item
    const drawLineItemsBySuperLineItem = mapValues(
      lineItemsBySuperLineItem,
      (lineItems) => {
        return lineItems.map((budgetLineItem) => {
          const drawLineItem = drawLineItemsById[budgetLineItem.id];
          return {
            ...drawLineItem,
            superLineItem: budgetLineItem.superLineItem,
          };
        });
      }
    );

    const superLineItems = mapValues(
      drawLineItemsBySuperLineItem,
      (lineItemGroup) => {
        const { superLineItem } = lineItemGroup[0];
        if (!superLineItem) return {};

        const aggregatedFields = getSuperLineItemAggregates(lineItemGroup);

        return {
          id: superLineItem.id,
          ...aggregatedFields,
        };
      }
    );

    // if a line item has no super line item, it is its own super line item
    const mappedSuperLineItems = budgetlineItems.map((lineItem) =>
      lineItem.superLineItem
        ? superLineItems[lineItem.superLineItem.id]
        : drawLineItemsById[lineItem.id]
    );

    return {
      ...draw,
      lineItems: uniqBy(mappedSuperLineItems, "id"),
    };
  });
}

function prepareSuperLineItems(budgetLineItems) {
  const superLineItems = mapValues(
    groupBy(budgetLineItems, "superLineItem.id"),
    (lineItemGroup) => {
      const { superLineItem, division } = lineItemGroup[0];

      if (!superLineItem) return {};

      const aggregatedFields = getSuperLineItemAggregates(lineItemGroup);

      return {
        id: superLineItem.id,
        name: superLineItem.name,
        division,
        position: minBy(lineItemGroup, "position").position,
        isSuperLineItem: true,
        ...aggregatedFields,
      };
    }
  );

  // if a line item has no super line item, it is its own super line item
  const mappedSuperLineItems = budgetLineItems.map((lineItem) =>
    lineItem.superLineItem
      ? superLineItems[lineItem.superLineItem.id]
      : lineItem
  );

  // only show the first instance of any mapped super line item in the budget
  return uniqBy(mappedSuperLineItems, "id");
}
