import { useEffect, Fragment, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  DoubleChevronDownIcon,
  DoubleChevronUpIcon,
  ErrorIcon,
} from "evergreen-ui";
import PropTypes from "prop-types";
import { Pane, Text, Tooltip } from "components/materials";
import {
  currencyColumnDefaults,
  FastDataTable,
  numberColumnDefaults,
  percentColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
  toBase64,
} from "components/materials/FastDataTable";
import { get } from "lodash";
import { majorScale, ThemeContext } from "helpers/utilities";
import { add, divide, subtract, sumBy } from "helpers/math";
import { DRAW_DIVISIONS_FRAGMENT } from "helpers/fragments";
import { failedRules, hasFailedRules } from "helpers/ruleHelpers";
import {
  getLineItemPosition,
  renderCommentIcon,
  renderRetainageIcon,
} from "helpers/lineItemTableHelpers";
import { getPercentAggregate } from "helpers/tableAggregateHelpers";
import { css } from "glamor";

const DRAW_LINE_ITEMS_QUERY = gql`
  query DocumentReviewDrawLineItemsQuery(
    $projectId: String!
    $drawId: String!
  ) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        id
        ...DrawDivisionsFragment
      }
    }
  }
  ${DRAW_DIVISIONS_FRAGMENT}
`;

const SUBSCRIPTION = gql`
  subscription drawRulesUpdated($drawId: String!) {
    drawRulesUpdated(drawId: $drawId) {
      id
      ...DrawDivisionsFragment
    }
  }
  ${DRAW_DIVISIONS_FRAGMENT}
`;

const defaultConfig = toBase64({
  columnConfig: [
    "lineItem",
    "currentBudget",
    "currentAmountRequested",
    "grossCurrentAmountRequested",
    "documentAmount",
    "percentRemaining",
    "grossPercentRemaining",
  ],
  filterConfig: [],
  groupConfig: { columnId: "division", expanded: undefined },
  sortConfig: { columnId: "budgetOrder", direction: "asc" },
});

const columns = [
  {
    ...stringColumnDefaults,
    ...primaryColumnDefaults,
    header: "Line Item",
    id: "lineItem",
    value: (lineItem) => lineItem.name,
    valueFormatter: (name, lineItem) => (
      <Fragment>
        {name}
        {hasFailedRules(lineItem) && (
          <Tooltip content={failedRules(lineItem)}>
            <ErrorIcon color="danger" size={10} marginLeft={majorScale(1)} />
          </Tooltip>
        )}
        {renderCommentIcon(lineItem)}
        {renderRetainageIcon(lineItem)}
      </Fragment>
    ),
    width: 220,
    aggregate: (_lineItems, section) => section,
  },
  {
    ...currencyColumnDefaults,
    header: "Current Budget",
    id: "currentBudget",
    value: (lineItem) => lineItem.budgetAmount,
    aggregate: (lineItems) => sumBy(lineItems, "budgetAmount"),
    width: 100,
  },
  {
    ...currencyColumnDefaults,
    header: "Current Amount Requested (Net)",
    id: "currentAmountRequested",
    value: (lineItem) => lineItem.requestedAmount,
    aggregate: (lineItems) => sumBy(lineItems, "requestedAmount"),
    width: 100,
  },
  {
    ...currencyColumnDefaults,
    header: "Current Amount Requested (Gross)",
    id: "grossCurrentAmountRequested",
    value: (lineItem) => lineItem.grossRequestedAmount,
    aggregate: (lineItems) => sumBy(lineItems, "grossRequestedAmount"),
    width: 100,
  },
  {
    ...currencyColumnDefaults,
    header: "Found in Supporting Documents",
    id: "documentAmount",
    aggregate: (lineItems) =>
      sumBy(lineItems, "grossDocumentedRequestedAmount"),
    value: (lineItem) => lineItem.grossDocumentedRequestedAmount,
    width: 100,
  },
  {
    ...percentColumnDefaults,
    header: "% Remaining (Net)",
    id: "percentRemaining",
    aggregate: (lineItems) =>
      getPercentAggregate(lineItems, "percentRemaining", "budgetAmount"),
    value: (lineItem) =>
      lineItem.types?.includes("CONTINGENCY")
        ? divide(
            lineItem.balanceToFundAmount,
            add(
              lineItem.originalBudgetAmount,
              lineItem.untrackedContingencyAmount
            )
          )
        : lineItem.percentRemaining,
    width: 100,
  },
  {
    ...percentColumnDefaults,
    header: "% Remaining (Gross)",
    id: "grossPercentRemaining",
    aggregate: (lineItems) =>
      getPercentAggregate(lineItems, "grossPercentRemaining", "budgetAmount"),
    value: (lineItem) =>
      lineItem.types?.includes("CONTINGENCY")
        ? divide(
            subtract(
              lineItem.balanceToFundAmount,
              lineItem.retainageToDateAmount
            ),
            add(
              lineItem.originalBudgetAmount,
              lineItem.untrackedContingencyAmount
            )
          )
        : lineItem.grossPercentRemaining,
    width: 100,
  },
  {
    ...stringColumnDefaults,
    header: "Division",
    id: "division",
    groupable: true,
    value: (lineItem) => lineItem.division.name,
  },
  {
    ...numberColumnDefaults,
    header: "Budget Order",
    id: "budgetOrder",
    value: getLineItemPosition,
    width: 100,
  },
];

export function LineItemDrawer({
  cardsOpen,
  drawId,
  openLineItemDrawer,
  projectId,
  setOpenLineItemDrawer,
}) {
  const { data, subscribeToMore } = useQuery(DRAW_LINE_ITEMS_QUERY, {
    variables: { projectId, drawId },
  });

  useEffect(() => {
    return subscribeToMore({
      document: SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        return {
          ...prev,
          draw: get(subscriptionData, "data.drawRulesUpdated"),
        };
      },
      variables: { drawId },
    });
  }, [drawId, subscribeToMore]);
  const theme = useContext(ThemeContext);
  const lineItems = get(data, "project.draw.lineItems", []);
  const borderStyling = {
    borderBottom: "1px solid #d7e0ea",
    borderTop: "1px solid #d7e0ea",
  };
  if (!lineItems || lineItems.length === 0) return null;
  const lineItemDrawerClassName = css({
    position: "fixed",
    bottom: 0,
    transition: "left .35s, right .35s",
    left: cardsOpen ? 200 : 0,
    right: cardsOpen ? "calc((100% - 200px) * 0.5)" : "50%",
    zIndex: 5,
  });
  const drawerClosedClassName = css({
    height: 0,
    overflowY: "hidden",
    transition: "height 0.35s",
  });
  const drawerOpenClassName = css({
    height: 250,
    overflowY: "scroll",
    transition: "height 0.35s",
  });

  return (
    <Pane className={lineItemDrawerClassName}>
      <Pane
        backgroundColor="#f1f8ff"
        boxShadow="0 -3px rgba(5,64,127,0.04)"
        cursor="pointer"
        display="flex"
        alignItems="center"
        onClick={() => {
          setOpenLineItemDrawer(!openLineItemDrawer);
        }}
        paddingX={majorScale(2)}
        paddingY={majorScale(1)}
        {...borderStyling}
      >
        <Pane display="flex" alignItems="center">
          {openLineItemDrawer ? (
            <DoubleChevronDownIcon
              color={theme.colors.baseBlue}
              marginRight={majorScale(1)}
            />
          ) : (
            <DoubleChevronUpIcon
              color={theme.colors.baseBlue}
              marginRight={majorScale(1)}
            />
          )}
          <Text
            color={theme.colors.baseBlue}
            fontWeight={theme.fontWeights.MEDIUM}
          >
            {openLineItemDrawer
              ? "Hide Draw Line Items"
              : "Show Draw Line Items"}
          </Text>
        </Pane>
      </Pane>
      <Pane
        className={
          openLineItemDrawer ? drawerOpenClassName : drawerClosedClassName
        }
      >
        <FastDataTable
          columns={columns}
          items={lineItems}
          serialized={defaultConfig}
          getRowState={(item) => (hasFailedRules(item) ? "error" : undefined)}
          onClickRow={null}
        />
      </Pane>
    </Pane>
  );
}

LineItemDrawer.propTypes = {
  cardsOpen: PropTypes.bool,
  drawId: PropTypes.string,
  projectId: PropTypes.string,
  openLineItemDrawer: PropTypes.bool,
  setOpenLineItemDrawer: PropTypes.bool,
};
