import { range } from "lodash";

// slope of line formula: m = (y2 - y1) / (x2 - x1)
export function getSlopeOfLine(x1: number, y1: number, x2: number, y2: number) {
  const yDifference = y2 - y1;
  const xDifference = x2 - x1;

  return yDifference / xDifference;
}

// y = m * x
export function getCoordinatesFromSlopeGivenY(m: number, x: number) {
  const y = m * x;
  return { x, y };
}

// based off of the distance formula squareRoot((x2 - x1)^2 + (y2 - y1)^2)
export function getDeviationFromLine(
  x1: number,
  y1: number,
  x2: number,
  y2: number
) {
  const xDifferenceSquared = (x2 - x1) ** 2;
  const yDifferenceSquared = (y2 - y1) ** 2;
  const deviation = Math.sqrt(xDifferenceSquared + yDifferenceSquared);
  return Math.abs(deviation);
}

export function getDataColor(deviation: number) {
  if (deviation < 0.05) {
    return {
      // green600
      dataColor: "#429777",
      // green400
      activeDrawColor: "#75CAAA",
      badgeColor: "green",
    };
  }
  if (deviation >= 0.05 && deviation < 0.1) {
    return {
      // orange500
      dataColor: "#FFB020",
      // yellow100
      activeDrawColor: "#FFEFD2",
      badgeColor: "yellow",
    };
  }
  return {
    // red600
    dataColor: "#A73636",
    // red300
    activeDrawColor: "#EE9191",
    badgeColor: "red",
  };
}

// variable k in the s-curve formula
export function calculateSteepnessForSCurve(duration: number) {
  switch (true) {
    case duration <= 20:
      return 0.6;
    case duration > 20 && duration <= 35:
      return 0.4;
    case duration > 35 && duration <= 50:
      return 0.3;
    case duration > 50 && duration <= 60:
      return 0.2;
    case duration > 60:
      return 0.1;
    default:
      return 0.6;
  }
}

// variable x0 in the s-curve formula
export function calculateAverageDuration(duration: number) {
  const sum: number = Array.from({ length: duration }).reduce(
    (acc: number, _, index) => acc + (index + 1),
    0
  );
  return sum / duration;
}

// L / (1 + e^(-k * (x-x0)))
// L - maximum value of the curve
// k - steepness of the curve (i.e. steepness)
// x - current period (i.e. period)
// x0 - avg of all the periods (i.e. durationAvg)
export function calculateSCurveDataPoint(
  k: number,
  L: number,
  x: number,
  x0: number
) {
  const power = -k * (x - x0);
  const denominator = 1 + Math.exp(power);
  const y = L / denominator;

  return { x, y };
}

export function calculateSCurveValues(duration: number, L: number) {
  const k = calculateSteepnessForSCurve(duration);
  const x0 = calculateAverageDuration(duration);

  return range(1, duration + 1).map((x) =>
    calculateSCurveDataPoint(k, L, x, x0)
  );
}
