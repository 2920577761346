import React, { useContext } from "react";
import { flatMap } from "lodash";
import { ArrowRightIcon, MapIcon, ScatterPlotIcon } from "evergreen-ui";
import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import {
  Badge,
  Button,
  Heading,
  IconButton,
  Pane,
  Paragraph,
  Tooltip,
  EmptyCard,
  ConfigureButtons,
} from "components/materials";
import {
  majorScale,
  minorScale,
  Position,
  ThemeContext,
} from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { DRAW_STATE, PERMISSION_ACTION } from "helpers/enums";
import { formatDate } from "helpers/dateHelpers";
import formatPercent from "helpers/formatPercent";
import t from "helpers/translate";
import { requestedOverTimeViewConfig } from "components/containers/BudgetPage/viewConfigs";
import { getDataColor, getDeviationFromLine } from "./graphHelpers";

const EXCLUDED_DRAW_STATES = [DRAW_STATE.REJECTED, DRAW_STATE.WITHDRAWN];

function hasHardCostContingencyLineItems(divisions) {
  const lineItems = flatMap(divisions, (division) => division.lineItems);

  const hardCostContingencyLineItems = lineItems.filter(
    (lineItem) => lineItem.isContingency && lineItem.hardCosts
  );

  return hardCostContingencyLineItems.length > 0;
}

function getData(hardCostPercentages) {
  return hardCostPercentages.map(
    ({ percentHardCostContingencyUsed, percentHardCostCompleted }) => ({
      x: percentHardCostContingencyUsed,
      y: percentHardCostCompleted,
    })
  );
}

function getDataWithLabels(hardCostPercentages) {
  return hardCostPercentages.map(
    ({
      percentHardCostContingencyUsed,
      percentHardCostCompleted,
      drawName,
      drawState,
      fundedAt,
    }) => {
      const drawStatusLabel =
        drawState === "funded"
          ? `Funded on: ${formatDate(fundedAt)}`
          : t(`drawStates.${drawState}`);
      return {
        x: percentHardCostContingencyUsed,
        y: percentHardCostCompleted,
        label: `${drawName} \n ${drawStatusLabel}`,
        size: fundedAt ? 3 : 5,
        fundedAt,
      };
    }
  );
}

export const CONTINGENCY_RISK_CONFIGURATION_SETTINGS = {
  i: "contingencyRisk",
  x: 0,
  y: 3,
  w: 1,
  h: 1,
  disabled: false,
};

export function ContingencyRisk({
  history,
  isConfigurable,
  isDisabled,
  project,
  cards,
  setCards,
  name,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);
  const canEditBudget = hasPermission(PERMISSION_ACTION.EDIT_BUDGET);
  const isMissingBudget = project.divisions.length === 0;
  const hasHardCostContingencyLineItem = hasHardCostContingencyLineItems(
    project.divisions
  );

  const draws = project.hardCostPercentagesByDraw.filter(
    (draw) => !EXCLUDED_DRAW_STATES.includes(draw.drawState.toUpperCase())
  );
  const hasNoDraws = draws.length === 0;

  const mostRecentDraw = hasNoDraws
    ? { percentHardCostContingencyUsed: 0, percentHardCostCompleted: 0 }
    : draws[draws.length - 1];
  const {
    percentHardCostContingencyUsed,
    percentHardCostCompleted,
  } = mostRecentDraw;

  // since the slope of the line we want to get the deviation from is y=x,
  // we can just use the coordinates of percentHardCostContingencyUsed for the x1 and y1 values
  const deviation = getDeviationFromLine(
    percentHardCostContingencyUsed,
    percentHardCostContingencyUsed,
    percentHardCostContingencyUsed,
    percentHardCostCompleted
  );
  const graphTrendlineColor = "#CACDD8";
  const { dataColor, activeDrawColor, badgeColor } = getDataColor(deviation);

  if (isMissingBudget) {
    return (
      <EmptyCard
        name="Contingency Risk"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
        icon={<ScatterPlotIcon color="muted" />}
      >
        {canEditBudget ? (
          <React.Fragment>
            <Paragraph paddingBottom={majorScale(1)}>
              This project does not have a budget yet.
            </Paragraph>
            <Button
              marginTop={8}
              appearance="primary"
              onClick={() => history.push(`/projects/${project.id}/budget`)}
            >
              Add Budget
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MapIcon size={majorScale(3)} />
            <Paragraph marginTop={majorScale(2)}>
              This project does not have a budget yet.
            </Paragraph>
            <Paragraph>You do not have access to add a budget.</Paragraph>
            <Paragraph>Please contact your admin.</Paragraph>
          </React.Fragment>
        )}
      </EmptyCard>
    );
  }

  if (!hasHardCostContingencyLineItem) {
    return (
      <EmptyCard
        name="Contingency Risk"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
        icon={<ScatterPlotIcon color="muted" />}
      >
        <Paragraph paddingBottom={majorScale(1)}>
          This project does not have any hard cost contingency line items.
        </Paragraph>
        <Button
          marginTop={8}
          appearance="primary"
          onClick={() =>
            history.push(
              `/projects/${project.id}/settings?settings=lineItemSettings`
            )
          }
        >
          Set Up Contingency
        </Button>
      </EmptyCard>
    );
  }

  return (
    <Pane
      width="100%"
      height="100%"
      position="relative"
      padding={majorScale(2)}
    >
      <Pane
        id="header"
        display="flex"
        marginBottom={minorScale(3)}
        borderBottom={`1px solid ${theme.colors.gray100}`}
        paddingBottom={majorScale(2)}
        justifyContent="space-between"
        alignItems="center"
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            lineHeight={1.2}
            fontWeight={500}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Contingency Risk
          </Heading>
          <ScatterPlotIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        <Pane display="flex" alignItems="center">
          <Pane textAlign="right" marginRight={minorScale(5)}>
            <Paragraph color={theme.colors.gray700} fontSize={minorScale(3)}>
              Cost Deviation
            </Paragraph>
            <Tooltip
              content={
                hasNoDraws
                  ? "No draws yet"
                  : "The distance from the trendline to the most recent draw"
              }
              position={Position.TOP}
            >
              <Badge color={badgeColor}>
                {hasNoDraws ? "n/a" : formatPercent(deviation)}
              </Badge>
            </Tooltip>
          </Pane>
          {isConfigurable && (
            <ConfigureButtons
              isDisabled={isDisabled}
              cards={cards}
              setCards={setCards}
              name={name}
            />
          )}
          {!isConfigurable && (
            <IconButton
              icon={ArrowRightIcon}
              onClick={() =>
                history.push(
                  `/projects/${
                    project.id
                  }/budget?table=${requestedOverTimeViewConfig()}`
                )
              }
              appearance="minimal"
              border={`1px solid ${theme.colors.gray400}`}
              marginLeft="auto"
            />
          )}
        </Pane>
      </Pane>
      <Pane height="85%" width="100%">
        <VictoryChart
          padding={{ top: 10, bottom: 45, left: 65, right: 40 }}
          height={200}
          width={520}
          containerComponent={<VictoryContainer responsive={false} />}
        >
          <VictoryAxis
            crossAxis
            tickValues={[0, 0.2, 0.4, 0.6, 0.8, 1]}
            tickFormat={(x) => `${x * 100}%`}
            axisLabelComponent={<VictoryLabel y={185} />}
            label="% Hard Cost Contingency Used (Gross)"
            style={{
              axis: { stroke: theme.colors.gray300 },
              axisLabel: {
                fontSize: 12,
                fontFamily: "Avenir",
                fill: theme.colors.textGray,
              },
              tickLabels: {
                fontSize: 10,
                fontFamily: "Avenir",
                fill: theme.colors.textGray,
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickValues={[0, 0.2, 0.4, 0.6, 0.8, 1]}
            tickFormat={(y) => `${y * 100}%`}
            axisLabelComponent={<VictoryLabel x={20} y={85} />}
            label="% Hard Cost Complete (Gross)"
            style={{
              tickLabels: {
                fontSize: 10,
                fontFamily: "Avenir",
                fill: theme.colors.textGray,
              },
              axisLabel: {
                fontSize: 12,
                fontFamily: "Avenir",
                fill: theme.colors.textGray,
              },
              axis: { stroke: "#FFFFFF" },
              grid: { stroke: theme.colors.backgroundGray },
            }}
          />
          <VictoryLine
            name="actual-trend"
            data={getData(draws)}
            style={{
              data: { stroke: dataColor },
            }}
          />
          <VictoryScatter
            labelComponent={
              <VictoryTooltip
                style={{
                  fontFamily: "Avenir",
                  stroke: theme.colors.textGray,
                  fontSize: "12px",
                }}
                flyoutStyle={{
                  fill: theme.colors.backgroundGray,
                  height: "42px",
                  stroke: 0,
                }}
              />
            }
            name="draw-data-points"
            data={getDataWithLabels(draws)}
            style={{
              data: {
                fill: (draw) => (draw.fundedAt ? dataColor : activeDrawColor),
                stroke: dataColor,
                strokeWidth: 2,
              },
              labels: { fontFamily: "Avenir" },
            }}
          />
          <VictoryLine
            name="ideal-trend"
            data={[
              { x: 0, y: 0 },
              { x: 1, y: 1 },
            ]}
            style={{
              data: { stroke: graphTrendlineColor, strokeDasharray: "14, 12" },
            }}
          />
        </VictoryChart>
      </Pane>
    </Pane>
  );
}
