import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon } from "evergreen-ui";
import { IconButton, Pane, ConfigureButtons } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";

export const OUTSTANDING_DRAWS_CONFIGURATION_SETTINGS = {
  i: "outstandingDraws",
  x: 0,
  y: 3,
  w: 1,
  h: 1,
  disabled: false,
};

export function OutstandingDraws({
  cards,
  isConfigurable,
  isDisabled,
  name,
  projects,
  setCards,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  // Edit to check if any draws match criteria when implemented
  const showBlankSlate = projects.length === 0;

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Longest Outstanding Draws" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => {
              history.push("/");
            }}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
          />
        )}
      </Pane>
      {showBlankSlate && <BlankSlate type={BlankSlateType.Draws} />}
    </Pane>
  );
}
