import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon } from "evergreen-ui";
import { hover } from "glamor";
import { VictoryContainer, VictoryPie, VictoryTooltip } from "victory";
import {
  find as lodashFind,
  get,
  groupBy,
  keys,
  includes,
  map as lodashMap,
  values,
  sumBy,
} from "lodash";
import {
  Pane,
  Progress,
  Shortener,
  Tab,
  Tablist,
  Text,
} from "components/materials";
import { UserContext } from "helpers/behaviors";
import { BRAND_COLORS, COLORS, GRAPH_COLOR_SCALE } from "helpers/colors";
import { ORGANIZATION_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { formatShortenedCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import {
  getBudgetAggregatesForGroupedProjects,
  getCommitmentAggregatesForGroupedProjects,
} from "helpers/fundingSourceHelpers";
import { divide } from "helpers/math";
import t from "helpers/translate";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { getSerializedURLForProjectReport } from "./helpers";
import ConfigureButtons from "../../materials/ConfigureButtons";
import { BlankSlate } from "./BlankSlate";
import { Table } from "./Table";
import { CardHeading } from "./CardHeading";

export const PORTFOLIO_COMPOSITION_CONFIGURATION_SETTINGS = {
  i: "portfolioComposition",
  x: 0,
  y: 0,
  w: 2,
  h: 1.15,
  disabled: false,
};

const VIEW = {
  PRODUCT_TYPE: "PRODUCT_TYPE",
  REGION: "REGION",
  BORROWER: "BORROWER",
  GENERAL_CONTRACTOR: "GENERAL_CONTRACTOR",
};

const COMPOSITION_VIEWS_CONFIG = {
  [VIEW.PRODUCT_TYPE]: {
    defaultGroup: "(No Type)",
    defaultTableValue: "-",
    getTableFilter: (value) => ({ enum: [value] }),
    groupingFunction: (project) => project?.productType?.type,
    tableColumnId: "productType",
  },
  [VIEW.REGION]: {
    defaultGroup: "(No Region)",
    defaultTableValue: "-",
    getTableFilter: (value) => ({ enum: [value] }),
    groupingFunction: (project) => project?.projectRegion?.region,
    tableColumnId: "region",
  },
  [VIEW.BORROWER]: {
    defaultGroup: "(No Borrower)",
    defaultTableValue: "None",
    getTableFilter: (value) => ({ list: [value] }),
    groupingFunction: (project) =>
      getStakeholder(project, ORGANIZATION_TYPE.BORROWER),
    tableColumnId: "borrowers",
  },
  [VIEW.GENERAL_CONTRACTOR]: {
    defaultGroup: "(No General Contractor)",
    defaultTableValue: "None",
    getTableFilter: (value) => ({ list: [value] }),
    groupingFunction: (project) =>
      getStakeholder(project, ORGANIZATION_TYPE.CONTRACTOR),
    tableColumnId: "generalContractors",
  },
};

const DEFAULT_GROUPS = lodashMap(COMPOSITION_VIEWS_CONFIG, "defaultGroup");

function getStakeholder(project, role) {
  const foundStakeholder = lodashFind(project.stakeholders, {
    role,
  });

  return get(foundStakeholder, "organization.name");
}

export function PortfolioComposition({
  cards,
  isConfigurable,
  isDisabled,
  name,
  organization,
  projects,
  setCards,
}) {
  const [selectedView, setSelectedView] = useState(VIEW.PRODUCT_TYPE);

  const {
    defaultGroup,
    getTableFilter,
    groupingFunction,
    defaultTableValue,
    tableColumnId,
  } = COMPOSITION_VIEWS_CONFIG[selectedView];

  const { hasPermission } = useContext(UserContext);

  const canAccessStakeholders = hasPermission(
    PERMISSION_ACTION.ACCESS_STAKEHOLDERS,
    organization
  );

  const isAdvancedReportDeveloper = hasPermission(
    PERMISSION_ACTION.ADVANCED_REPORT_DEVELOPER,
    organization
  );

  const viewAvailableBy = {
    PRODUCT_TYPE: true,
    REGION: true,
    BORROWER: !isAdvancedReportDeveloper && canAccessStakeholders,
    GENERAL_CONTRACTOR: canAccessStakeholders,
  };

  const availableViews = values(VIEW).filter((view) => viewAvailableBy[view]);

  const groupedProjects = groupBy(
    projects,
    (project) => groupingFunction(project) || defaultGroup
  );

  const groupData = keys(groupedProjects).map((groupName, index) => {
    const projectGroup = groupedProjects[groupName];
    const {
      totalDisbursedAmount,
      totalFundedAmount,
    } = getCommitmentAggregatesForGroupedProjects(projectGroup);

    const {
      totalBudgetAmount,
      totalSpentAmount,
    } = getBudgetAggregatesForGroupedProjects(projectGroup);

    const isDefaultGroup = includes(DEFAULT_GROUPS, groupName);
    const tableValue = isDefaultGroup ? defaultTableValue : groupName;
    const tableFilter = getTableFilter(tableValue);

    return {
      budgeted: totalBudgetAmount,
      color: GRAPH_COLOR_SCALE[index % GRAPH_COLOR_SCALE.length],
      committed: totalFundedAmount,
      funded: totalDisbursedAmount,
      groupName,
      navigateUrl: getSerializedURLForProjectReport(
        tableColumnId,
        tableFilter,
        organization.id
      ),
      numProjects: projectGroup.length,
      pctBudgetComplete: divide(totalSpentAmount, totalBudgetAmount),
      pctCommitmentsFunded: divide(totalDisbursedAmount, totalFundedAmount),
      projects: projectGroup,
      spent: totalSpentAmount,
    };
  });

  const tableData = groupData.sort(
    (groupA, groupB) => groupB.numProjects - groupA.numProjects
  );

  const pieData = tableData.map(({ groupName, projects }) => ({
    x: groupName,
    y: projects.length,
  }));

  const columns = [
    {
      name: t(`portfolioInsightsPage.compositionViews.${selectedView}`),
      renderCell: (row) => <GroupNameCell row={row} />,
      renderFooterCell: (_rows) => (
        <Text color="black" fontWeight={700}>
          TOTAL
        </Text>
      ),
      sortKey: "groupName",
      width: "20%",
    },
    {
      headerAlign: "center",
      name: "% of Commitments Funded",
      renderCell: (row) => <CommitmentCell row={row} />,
      renderFooterCell: (rows) => <CommitmentTotalCell rows={rows} />,
      sortKey: "pctCommitmentsFunded",
      width: "40%",
    },
    {
      headerAlign: "center",
      name: "% of Budget Complete",
      renderCell: (row) => <BudgetCell row={row} />,
      renderFooterCell: (rows) => <BudgetTotalCell rows={rows} />,
      sortKey: "pctBudgetComplete",
      width: "40%",
    },
  ];

  const showBlankSlate = tableData.length === 0;

  return (
    <Pane
      width="100%"
      height={300}
      borderRadius={majorScale(2)}
      paddingY={majorScale(2)}
      paddingX={majorScale(3)}
    >
      <Pane
        display="flex"
        justifyContent="space-between"
        marginBottom={majorScale(1)}
      >
        <CardHeading disabled={isDisabled} text="Portfolio Composition" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
      </Pane>
      <Pane width="100%">
        <Tablist marginBottom={majorScale(2)}>
          {availableViews.map((view) => (
            <Tab
              key={view}
              isSelected={selectedView === view}
              onSelect={() => setSelectedView(view)}
            >
              {t(`portfolioInsightsPage.compositionViews.${view}`)}
            </Tab>
          ))}
        </Tablist>
        {showBlankSlate ? (
          <BlankSlate />
        ) : (
          <Pane display="flex" justifyContent="space-between" width="100%">
            <DonutChart pieData={pieData} numProjects={projects.length} />
            <Table
              columns={columns}
              defaultSortDirection="asc"
              defaultSortKey="groupName"
              footerProps={{
                row: { backgroundColor: "#F9FAFC", height: "56px" },
              }}
              marginLeft={majorScale(4)}
              rows={tableData}
            />
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}

function DonutChart({ numProjects, pieData }) {
  return (
    <Pane height="300">
      <VictoryContainer height={400} width={400}>
        <VictoryPie
          colorScale={GRAPH_COLOR_SCALE}
          data={pieData}
          innerRadius={100}
          labelComponent={
            <VictoryTooltip
              cornerRadius={5}
              pointerLength={5}
              flyoutStyle={{
                fill: COLORS.BLACK,
                stroke: "none",
              }}
              style={{
                fill: BRAND_COLORS.WHITE,
                fontSize: 20,
                fontFamily: "AvenirNext, arial, sans-serif",
              }}
            />
          }
          labels={({ x: productType, y: numProjects }) =>
            `${productType}\n${numProjects} ${
              numProjects > 1 ? "Projects" : "Project"
            }`
          }
          radius={180}
          standalone={false}
        />
        <VictoryPie
          colorScale={["#EAECF0"]}
          data={[{ x: 1, y: 1 }]}
          innerRadius={92}
          labels={[]}
          radius={100}
          standalone={false}
        />
        <VictoryPie
          colorScale={["#EAECF0"]}
          data={[{ x: 1, y: 1 }]}
          innerRadius={180}
          labels={[]}
          radius={188}
          standalone={false}
        />
        <InnerLabel numProjects={numProjects} />
      </VictoryContainer>
    </Pane>
  );
}

function InnerLabel({ numProjects }) {
  const unit = numProjects > 1 ? "Projects" : "Project";
  const baseProps = { fontSize: 24, textAnchor: "middle", x: 200, y: 200 };

  return (
    <g>
      <text {...baseProps} dy={-15} fontSize={36}>
        {numProjects}
      </text>
      <text {...baseProps} dy={20}>
        {unit}
      </text>
    </g>
  );
}

function GroupNameCell({ row }) {
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const handleClick = () => {
    history.push(row.navigateUrl);
  };

  return (
    <Pane
      alignItems="center"
      cursor="pointer"
      display="flex"
      justifyContent="space-between"
      onClick={handleClick}
    >
      <Pane alignItems="center" display="flex">
        <Pane
          background={row.color}
          borderRadius="50%"
          height="16px"
          marginRight={majorScale(1)}
          width="16px"
        />
        <Shortener
          className={hover({
            textDecoration: "underline",
          })}
          color={theme.colors.linkBlue}
          fontSize="14px"
          limit={32}
          size={400}
          text={row.groupName}
        />
      </Pane>
      <ArrowRightIcon color={theme.colors.linkBlue} />
    </Pane>
  );
}

function CommitmentCell({ row }) {
  const { committed, funded } = row;

  return (
    <Pane
      alignItems="center"
      columnGap={minorScale(3)}
      display="flex"
      width="100%"
    >
      <Pane width="55%">
        <Text>
          {`${formatShortenedCurrency(funded)} / ${formatShortenedCurrency(
            committed
          )}`}
        </Text>
      </Pane>
      <Pane width="35%">
        <Progress
          background="transparent"
          border="0.5px solid #8F95B2"
          color={funded > committed ? "#EE9191" : "#1770ED"}
          total={committed}
          value={funded}
          width="54px"
        />
      </Pane>
      <Pane textAlign="right" width="10%">
        <Text>{formatPercent(funded / committed, "0%")}</Text>
      </Pane>
    </Pane>
  );
}

function CommitmentTotalCell({ rows }) {
  const totalCommitted = sumBy(rows, "committed");
  const totalFunded = sumBy(rows, "funded");

  const pctCommitted = divide(totalFunded, totalCommitted);

  return (
    <Pane
      alignItems="center"
      columnGap={minorScale(3)}
      display="flex"
      width="100%"
    >
      <Pane width="55%">
        <Text fontWeight={700}>
          {`${formatShortenedCurrency(totalFunded)} / ${formatShortenedCurrency(
            totalCommitted
          )}`}
        </Text>
      </Pane>
      <Pane width="35%">
        <Progress
          background="transparent"
          border="0.5px solid #8F95B2"
          color="black"
          total={totalCommitted}
          value={totalFunded}
          width="54px"
        />
      </Pane>
      <Pane width="10%" textAlign="right">
        <Text fontWeight={700}>{formatPercent(pctCommitted)}</Text>
      </Pane>
    </Pane>
  );
}

function BudgetCell({ row }) {
  const { budgeted, pctBudgetComplete, spent } = row;

  return (
    <Pane
      alignItems="center"
      columnGap={minorScale(3)}
      display="flex"
      width="100%"
    >
      <Pane width="55%">
        <Text>
          {`${formatShortenedCurrency(spent)} / ${formatShortenedCurrency(
            budgeted
          )}`}
        </Text>
      </Pane>
      <Pane width="35%">
        <Progress
          background="transparent"
          border="0.5px solid #8F95B2"
          color={spent > budgeted ? "#EE9191" : "#1770ED"}
          total={budgeted}
          value={spent}
          width="54px"
        />
      </Pane>
      <Pane textAlign="right" width="10%">
        <Text>{formatPercent(pctBudgetComplete)}</Text>
      </Pane>
    </Pane>
  );
}

function BudgetTotalCell({ rows }) {
  const totalSpent = sumBy(rows, "spent");
  const totalBudgeted = sumBy(rows, "budgeted");

  const pctBudgetComplete = divide(totalSpent, totalBudgeted);

  return (
    <Pane
      alignItems="center"
      columnGap={minorScale(3)}
      display="flex"
      width="100%"
    >
      <Pane width="55%">
        <Text fontWeight={700}>
          {`${formatShortenedCurrency(totalSpent)} / ${formatShortenedCurrency(
            totalBudgeted
          )}`}
        </Text>
      </Pane>
      <Pane width="35%">
        <Progress
          background="transparent"
          border="0.5px solid #8F95B2"
          color="black"
          total={totalBudgeted}
          value={totalSpent}
          width="54px"
        />
      </Pane>
      <Pane width="10%" textAlign="right">
        <Text fontWeight={700}>{formatPercent(pctBudgetComplete)}</Text>
      </Pane>
    </Pane>
  );
}
