import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tablist } from "evergreen-ui";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import { keys } from "lodash";
import { ConfigureButtons, Pane } from "components/materials";
import { formatShortenedCurrency } from "helpers/formatCurrency";
import { BRAND_COLORS, COLORS } from "helpers/colors";
import t from "helpers/translate";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import {
  calculateAverageDuration,
  calculateSCurveDataPoint,
  calculateSCurveValues,
  calculateSteepnessForSCurve,
} from "../ProjectDashboard/graphHelpers";

export const SCHEDULE_RISK_CONFIGURATION_SETTINGS = {
  i: "scheduleRisk",
  x: 1,
  y: 1,
  w: 1,
  h: 1.5,
  disabled: false,
};

const GRAPH_TYPE = {
  S_CURVE: "S_CURVE",
  LINEAR: "LINEAR",
};

const GRAPH_FILTER = {
  OVER_TEN: "OVER_TEN",
  FIVE_TO_TEN: "FIVE_TO_TEN",
  WITHIN_FIVE: "WITHIN_FIVE",
  ALL: "ALL",
};

export function ScheduleRisk({
  cards,
  isConfigurable,
  isDisabled,
  name,
  projects,
  setCards,
}) {
  const [graphType, setGraphType] = useState(GRAPH_TYPE.S_CURVE);
  const [graphFilter, setGraphFilter] = useState(GRAPH_FILTER.OVER_TEN);

  const relevantProjects = projects.filter(
    ({ percentOfProjectTimeElapsed: elapsed }) => elapsed > 0 && elapsed < 1
  );

  const data = relevantProjects.map(
    ({
      expectedProjectLength,
      hardCostsAmount,
      hardCostsGrossPercentComplete,
      id: projectId,
      name: projectName,
      percentOfProjectTimeElapsed: percentProjectScheduleComplete,
    }) => {
      const deviationFn =
        graphType === GRAPH_TYPE.S_CURVE
          ? getDeviationWhenSCurve
          : getDeviationWhenLinear;

      return {
        expectedProjectLength,
        hardCostsAmount,
        hardCostsGrossPercentComplete,
        projectId,
        projectName,
        percentProjectScheduleComplete,
        percentScheduleDeviation: deviationFn(
          percentProjectScheduleComplete * 100,
          hardCostsGrossPercentComplete * 100
        ),
      };
    }
  );

  const filteredData = data.filter(({ percentScheduleDeviation }) => {
    switch (graphFilter) {
      case GRAPH_FILTER.OVER_TEN:
        return percentScheduleDeviation >= 0.1;
      case GRAPH_FILTER.FIVE_TO_TEN:
        return (
          percentScheduleDeviation >= 0.05 && percentScheduleDeviation <= 0.1
        );
      case GRAPH_FILTER.WITHIN_FIVE:
        return percentScheduleDeviation <= 0.05;
      default:
        return true;
    }
  });

  const showBlankSlate = filteredData.length === 0;
  const { GRAPH_COLOR_FOR_FILTER } = useScheduleRiskColors();

  return (
    <Pane
      display="flex"
      flexDirection="column"
      height="100%"
      padding={majorScale(2)}
      width="100%"
    >
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Schedule Risk" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            name={name}
            setCards={setCards}
          />
        )}
      </Pane>
      <Pane
        alignItems="center"
        display="flex"
        flexGrow="1"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Pane width="100%">
          <Tablist>
            {keys(GRAPH_TYPE).map((type) => (
              <Tab
                isSelected={type === graphType}
                key={type}
                onSelect={() => setGraphType(type)}
              >
                {t(`portfolioInsights.graphType.${type}`)}
              </Tab>
            ))}
          </Tablist>
        </Pane>
        {showBlankSlate ? (
          <BlankSlate type={BlankSlateType.ScheduleRisk} />
        ) : (
          <ScheduleRiskGraph data={filteredData} graphType={graphType} />
        )}
        <Tablist display="flex">
          {keys(GRAPH_FILTER).map((filter) => {
            const hasLegendIcon = filter !== GRAPH_FILTER.ALL;

            return (
              <Tab
                isSelected={filter === graphFilter}
                key={filter}
                onSelect={() => setGraphFilter(filter)}
              >
                <Pane alignItems="center" display="flex">
                  {hasLegendIcon && (
                    <Pane
                      backgroundColor={GRAPH_COLOR_FOR_FILTER[filter]}
                      borderRadius="50%"
                      height="12px"
                      marginRight={minorScale(1)}
                      width="12px"
                    />
                  )}
                  {t(`portfolioInsights.graphFilter.${filter}`)}
                </Pane>
              </Tab>
            );
          })}
        </Tablist>
      </Pane>
    </Pane>
  );
}

function ScheduleRiskGraph({ data, graphType }) {
  const history = useHistory();

  const { colorForDeviation } = useScheduleRiskColors();
  const theme = useContext(ThemeContext);

  const graphGray = theme.colors.borderGray;

  const graphData = data.map(
    ({
      expectedProjectLength,
      hardCostsAmount,
      hardCostsGrossPercentComplete,
      percentProjectScheduleComplete,
      percentScheduleDeviation,
      projectId,
      projectName,
    }) => {
      return {
        expectedProjectLength,
        hardCostsAmount,
        deviation: percentScheduleDeviation,
        projectId,
        projectName,
        x: percentProjectScheduleComplete * 100,
        y: hardCostsGrossPercentComplete * 100,
      };
    }
  );

  const getScatterPointLabelContent = ({
    expectedProjectLength,
    hardCostsAmount,
    projectName,
  }) =>
    `${projectName}\n
    \nDuration: ${expectedProjectLength} ${
      expectedProjectLength === 1 ? "month" : "months"
    }\nTotal Hard Costs Budget: ${formatShortenedCurrency(hardCostsAmount)}`;

  const lineData =
    graphType === GRAPH_TYPE.LINEAR
      ? [
          { x: 0, y: 0 },
          {
            x: 100,
            y: 100,
          },
        ]
      : [{ x: 0, y: 0 }, ...calculateSCurveValues(100, 100)];

  const onScatterPointClick = (_event, props) => {
    const { projectId } = props.datum;

    history.push(`/projects/${projectId}`);
  };

  return (
    <VictoryChart
      padding={{ top: 10, bottom: 45, left: 65, right: 40 }}
      height={300}
      width={520}
    >
      <VictoryLine
        data={lineData}
        style={{
          data: {
            stroke: graphGray,
            strokeDasharray: "14, 12",
          },
        }}
      />
      <VictoryAxis
        axisLabelComponent={<VictoryLabel y={300} />}
        crossAxis
        domain={[0, 105]}
        label="% Project Schedule Complete"
        style={{
          axis: { stroke: graphGray },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
          tickLabels: {
            fontSize: 10,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
        }}
        tickValues={[0, 20, 40, 60, 80, 100]}
      />
      <VictoryAxis
        axisLabelComponent={<VictoryLabel x={15} />}
        dependentAxis
        domain={[0, 105]}
        label="% Hard Cost Complete (Gross)"
        style={{
          axis: { stroke: graphGray },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
          grid: { stroke: graphGray },
          tickLabels: {
            fontSize: 10,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
        }}
        tickValues={[0, 20, 40, 60, 80, 100]}
      />
      <VictoryScatter
        data={graphData}
        events={[
          { target: "data", eventHandlers: { onClick: onScatterPointClick } },
        ]}
        labelComponent={
          <VictoryTooltip
            cornerRadius={5}
            flyoutStyle={{
              fill: COLORS.BLACK,
              height: "42px",
              stroke: 0,
            }}
            style={{
              fill: BRAND_COLORS.WHITE,
              fontFamily: "Avenir",
              fontSize: "12px",
            }}
          />
        }
        labels={getScatterPointLabelContent}
        size={6}
        style={{
          data: {
            cursor: "pointer",
            fill: ({ deviation }) => colorForDeviation(deviation),
            stroke: "transparent",
            strokeWidth: 10,
          },
        }}
      />
    </VictoryChart>
  );
}

function useScheduleRiskColors() {
  const theme = useContext(ThemeContext);

  const GRAPH_COLOR_FOR_FILTER = {
    [GRAPH_FILTER.OVER_TEN]: theme.status.overdue,
    [GRAPH_FILTER.FIVE_TO_TEN]: theme.status.paused,
    [GRAPH_FILTER.WITHIN_FIVE]: theme.status.funded,
  };

  function colorForDeviation(deviation) {
    if (deviation >= 0.1) return GRAPH_COLOR_FOR_FILTER[GRAPH_FILTER.OVER_TEN];
    if (deviation >= 0.05)
      return GRAPH_COLOR_FOR_FILTER[GRAPH_FILTER.FIVE_TO_TEN];

    return GRAPH_COLOR_FOR_FILTER[GRAPH_FILTER.WITHIN_FIVE];
  }

  return { colorForDeviation, GRAPH_COLOR_FOR_FILTER };
}

function getDeviationWhenSCurve(x, y) {
  const k = calculateSteepnessForSCurve(100);
  const x0 = calculateAverageDuration(100);

  const { y: trendLineY } = calculateSCurveDataPoint(k, 100, x, x0);

  return calculateYDeviation(y, trendLineY);
}

function getDeviationWhenLinear(x, y) {
  /* Trend line has a slope of 1 for this use case,
     so expectedY (trend line y) is equal to actualX
   */
  return calculateYDeviation(y, x);
}

function calculateYDeviation(actualY, expectedY) {
  /*
    For p1 (20, 15), and trend line p2 (20, 20)
      Deviation = |y1 - y2| / y2
      |20 - 15| / 20 = 0.25 = 25% deviation

    For p1 (30, 50), and trend line p2 (30, 30)
      Deviation = |y1 - y2| / y2
      |30 - 50| / 30 = 0.67 = 67% deviation
  */
  return Math.abs(actualY - expectedY) / expectedY;
}
