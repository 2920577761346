import { useContext } from "react";
import { HelpIcon } from "evergreen-ui";
import { Pane, Paragraph, Tooltip, Shortener } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import {
  minorScale,
  majorScale,
  Position,
  ThemeContext,
} from "helpers/utilities";
import { GRAPH_COLOR_SCALE } from "helpers/colors";

export function DonutChartLegend({
  contingencySegments,
  untrackedContingency,
  originalAmount,
}) {
  const theme = useContext(ThemeContext);

  const nameOverflowProps = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <Pane
      flex={1}
      textAlign="center"
      marginLeft={majorScale(2)}
      height="100%"
      width="48%"
      overflowY="auto"
    >
      {contingencySegments.map(({ name, value }, index) => {
        const isContingencyRemainingSegment = name === "Contingency Remaining";
        return (
          <Pane display="flex" key={name}>
            <Pane marginRight={4} display="flex" alignItems="center">
              <Pane
                borderRadius={12}
                height={12}
                width={12}
                background={
                  isContingencyRemainingSegment
                    ? theme.colors.borderGray
                    : GRAPH_COLOR_SCALE[index % GRAPH_COLOR_SCALE.length]
                }
              />
            </Pane>
            <Pane display="flex" width="180px" alignItems="center">
              <Shortener
                tooltipPosition={Position.LEFT}
                fontSize={14}
                fontWeight={isContingencyRemainingSegment ? 500 : 400}
                text={name}
                limit={23}
              />
            </Pane>
            <Paragraph
              marginLeft={minorScale(2)}
              fontWeight={isContingencyRemainingSegment ? 500 : 400}
            >
              {formatCurrency(value)}
            </Paragraph>
          </Pane>
        );
      })}
      <Pane display="flex" borderTop={`1px solid ${theme.colors.gray400}`}>
        <Pane marginRight={8} display="flex" alignItems="center">
          <Pane borderRadius={12} height={12} width={12} />
        </Pane>
        <Pane display="flex" width="180px">
          <Paragraph {...nameOverflowProps}>Original Contingency</Paragraph>
        </Pane>
        <Paragraph marginLeft={minorScale(2)}>
          {formatCurrency(originalAmount)}
        </Paragraph>
      </Pane>
      <Pane display="flex">
        <Pane marginRight={8} display="flex" alignItems="center">
          <Pane borderRadius={12} height={12} width={12} />
        </Pane>
        <Pane display="flex" width="180px">
          <Paragraph {...nameOverflowProps}>
            Non Allocated Adjustments
          </Paragraph>
          <Pane marginLeft={minorScale(1)} display="flex" alignItems="center">
            <Tooltip content="Adjustments to contingency line items that are not reallocated to another line item.">
              <HelpIcon color="default" size={minorScale(3)} />
            </Tooltip>
          </Pane>
        </Pane>
        <Paragraph marginLeft={minorScale(2)}>
          {formatCurrency(untrackedContingency)}
        </Paragraph>
      </Pane>
    </Pane>
  );
}
