import { useContext } from "react";
import { get } from "lodash";
import { Pane, Text } from "components/materials";
import { minorScale, ThemeContext } from "helpers/utilities";

export function Row({ columns, row, cellBorderX, cellBorderY }) {
  const theme = useContext(ThemeContext);
  const borderStyle = `1px solid ${theme.colors.borderGray}`;

  return (
    <tr style={{ height: "48px" }}>
      {columns.map((column, index) => {
        const fallback = (row) => <Text>{row[column.valueKey]}</Text>;
        const renderCell = get(column, "renderCell", fallback);

        return (
          <td
            key={`cell-${index}`}
            style={{
              border: borderStyle,
              borderRightWidth: cellBorderY ? "1px" : "0px",
              borderLeftWidth: cellBorderY ? "1px" : "0px",
              borderTopWidth: cellBorderX ? "1px" : "0px",
              borderBottomWidth: cellBorderX ? "1px" : "0px",
            }}
          >
            <Pane paddingX={minorScale(3)}>{renderCell(row)}</Pane>
          </td>
        );
      })}
    </tr>
  );
}
